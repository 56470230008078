import React from 'react';
import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';
import { ReactComponent as IconError } from '@/assets/images/icon/icon-error.svg';

const NetworkStatusAlert = () => {
  return (
    <div css={[styledNetworkStatusAlert]}>
      {/*<IconError fill={theme.color.line} />*/}
      <span className="icon">
        <IconError fill={'#FF607C'} />
      </span>
      <p>네트워크 연결이 원활하지 않습니다.</p>
    </div>
  );
};

export default NetworkStatusAlert;

const styledNetworkStatusAlert = css`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 0 0.9rem;
  border-bottom: 1px solid ${theme.color.line};
  background-color: #fff;
  text-align: center;
  z-index: 1;

  .icon {
    display: inline-block;
    margin-right: 0.4rem;
  }

  p {
    display: inline-block;
  }
`;
