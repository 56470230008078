import classNames from 'classnames';
import React from 'react';
import { styledLayoutContentWrap } from '@/components/layout/styleOfLayout';

interface TInternalProps extends React.HTMLAttributes<HTMLElement> {}

const LayoutBody = (props: TInternalProps) => {
  const { children, className, ...rest } = props;

  return (
    <section
      className={classNames(`layout-body flex flex-col flex-1 ${className || ''}`)}
      css={styledLayoutContentWrap}
      {...rest}
    >
      {children}
    </section>
  );
};
export default LayoutBody;
