import React from 'react';
import LayoutBodyMain from '@/components/layout/LayoutBodyMain';
import LayoutBody from '@/components/layout/LayoutBody';
import classNames from 'classnames';

interface AppBodyLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  pageHeader: React.ReactNode;
  bottom?: React.ReactNode;
  bottomFixed?: React.ReactNode;
  type?: 'detail' | 'infinite' /* 화면 유형( 상세 페이지에서 여백값 달라짐 )*/;
  classOfMain?: string; /*LayoutBodyMain 컴포넌트에서 주내용이 들어가는 박스 - 내용에 따라 영향을 주어야 하는 경우 */
}

function AppBodyLayout({ pageHeader, bottom, bottomFixed, children, type, classOfMain }: AppBodyLayoutProps) {
  return (
    <LayoutBody
      className={classNames({
        'detail': type === 'detail',
        'infinite': type === 'infinite',
      })}
    >
      {pageHeader}
      <LayoutBodyMain bottom={bottom} classOfMain={classOfMain}>{children}</LayoutBodyMain>
      {bottomFixed || null}
    </LayoutBody>
  );
}

export default AppBodyLayout;
