import ReactGA from 'react-ga4';

/**
 * 상용 웹사이트에서만 GA 설정
 */
export default function useRouteChangeTracker() {
  if (window.location.href.includes('https://app.sovoro.kr')) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
    ReactGA.send('pageview');
  }
}
