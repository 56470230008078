import React from 'react';
import { TLayoutProps } from '@/components/layout/Layout';
import { styledLayoutContent } from "@/components/layout/styleOfLayout";

interface TInternalProps extends React.HTMLAttributes<HTMLElement> {
  classOfMain?: string
  bottom?: TLayoutProps['bottom'];
}

const LayoutBodyMain = (props: TInternalProps) => {
  const { classOfMain, bottom, children, ...rest } = props;

  return (
    <>
      <div className="body-main flex flex-col flex-1" css={styledLayoutContent} {...rest}>
        <div className={`flex flex-col flex-1 ${classOfMain || ""}`}>{children}</div>
        {bottom || null}
      </div>
    </>
  );
};
export default LayoutBodyMain;

