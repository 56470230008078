import message, { ArgsProps } from 'antd/lib/message';
import React from 'react';

/*
 * - 'offline' 상태에서 버튼등의 이벤트를 실행하고자 할때.
 * - '휴지통' 비우기를 성공했을때
 * */

interface TInternalProps extends ArgsProps {
  noIcon?: boolean;
}

export const messageInfo = ({ content, noIcon = false, ...rest }: TInternalProps) => {
  const icon = noIcon ? { icon: <></> } : {};

  message.info({
    ...rest,
    content,
    className: 'custom',
    // duration: 0,
    ...icon,
  });
};
