import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTag = ({
  title,
}: {
  title?: string;
}) => {

  return (
    <Helmet>
      <title>SOVORO {title || ""}</title>
    </Helmet>
  );
};
export default MetaTag;
