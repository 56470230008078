import { useEffect, useState } from 'react';

export default function useNetworkMonitoring() {
  const [onLine, setOnLine] = useState(true);

  useEffect(() => {
    const networkMonitoring = setInterval(() => {
      setOnLine(navigator.onLine);
    }, 3000);
    return () => clearInterval(networkMonitoring);
  }, []);

  return onLine;
}
