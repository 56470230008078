import { css } from '@emotion/react';
import Button, { TButtonProps } from '@/components/button/Button';
import { ReactComponent as IconMobileOpenAside } from '@/assets/images/button/icon-open-aside.svg';
import React from 'react';

interface TInternalProps extends TButtonProps {}

const ButtonToOpenAside = (props: TInternalProps, ref: any) => {
  const { className, ...rest } = props;

  const styledCss = css``;

  return (
    <Button design="tertiary" className={className || ''} css={styledCss} ref={ref} {...rest}>
      <IconMobileOpenAside />
    </Button>
  );
};
export default React.forwardRef(ButtonToOpenAside);
