import React from 'react';
import SEOMetaTag from '@/components/SEOMetaTag';
import AppBodyLayout from '@/components/app/AppBodyLayout';
import PageHeader from '@/components/layout/PageHeader';
import { ReactComponent as Img } from '@/assets/images/img-coming-soon.svg';
import { styledTitle2 } from '@/assets/styles/styledText';
import { css } from '@emotion/react';
import theme from '@/assets/styles/theme';

function UsageState() {
  return (
    <>
      <SEOMetaTag title="사용현황" />
      <AppBodyLayout
        pageHeader={
          <PageHeader header={<h1 className="title text-ellipsis overflow-hidden">사용현황</h1>} />
        }
      >
        <section
          className="flex flex-1 flex-col justify-center items-center"
          css={styledComingSoon}
        >
          <Img />
          <h1 css={styledTitle2}>서비스 준비중입니다.</h1>
          <p>고객님께 더 나은 서비스를 드리기위해 준비하고 있습니다. 조금만 기다려주세요 :)</p>
        </section>
      </AppBodyLayout>
    </>
  );
}

export default UsageState;

const styledComingSoon = css`
  color: ${theme.color.default};
  text-align: center;

  h1 {
    margin-top: 3.6rem;
  }

  p {
    max-width: 25rem;
    margin-top: 1.6rem;
  }
`;
