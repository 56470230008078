import React from "react";

interface TInternalProps extends React.HTMLAttributes<HTMLElement> {}

const LayoutBodyAside = (
  props: TInternalProps,
) => {
  const { children } = props;

  return (
    <section
      className="body-aside flex-none"
    >
      {children}
    </section>
  );
};
export default LayoutBodyAside;
