import React, { forwardRef } from 'react';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { StyledTextArea } from '@/components/inputText/styleOfInput';

export interface TTextAreaProps extends TextAreaProps {
  isError?: boolean;
}

const TextArea = forwardRef<any, TTextAreaProps>((props, ref) => {
  const { isError, className, allowClear, showCount, maxLength, ...rest } = props;

  let { status } = props;

  if (isError) {
    status = 'error';
  }

  return (
    <>
      <StyledTextArea
        ref={ref}
        className={` ${className || ''}`}
        {...rest}
        status={status}
        allowClear={allowClear}
        showCount={showCount}
        maxLength={maxLength}
      />
    </>
  );
});
export default TextArea;
