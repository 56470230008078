import { css } from '@emotion/react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import theme from '@/assets/styles/theme';

export const Loading = ({
  layer,
  size = 'default',
  tip = 'Loading...',
  width,
}: {
  layer?: 'fixed' | 'absolute' | 'relative';
  size?: 'default' | 'small' | 'large';
  tip?: string;
  width?: string;
}) => {
  let wrapStyle = css`
    width: ${width ? width : '100%'};
    height: 100%;
    background-color: ${layer === 'absolute' ? 'rgba(255, 255, 255, .3)' : '#fff'};
    text-align: center;

    .ant-spin-text {
      margin-top: 1rem;
      color: ${theme.color.primary.default};
    }
  `;

  const beforeStyle = css`
    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      vertical-align: middle;
    }
  `;

  const styles = [wrapStyle, beforeStyle];

  if (layer) {
    const listStyle = css`
      position: ${layer};
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
    `;
    styles.push(listStyle);
  }

  let fontSize = '35px';
  switch (size) {
    case 'small':
      fontSize = '20px';
      break;
    case 'large':
      fontSize = '50px';
      break;
  }

  return (
    <div css={styles}>
      <Spin
        tip={tip}
        size={size}
        indicator={
          <LoadingOutlined
            spin
            style={{ fontSize: `${fontSize}`, color: `${theme.color.primary.default}` }}
          />
        }
      />
    </div>
  );
};

export default Loading;
